import { Icon } from '../../../components/Icon';
import { Button } from '../../../components/base';
import { useChat } from '../../../libs/hooks/useChat';

export const NewChatPrompt: React.FC = () => {
  const chat = useChat();

  return (
    <p className="chat-info">
      <Icon name="info" />
      연속 질문이 아니라면 새로 채팅을 시작하는 것이 좋아요.
      <Button
        variant="text"
        className="dsx-color-primary"
        onClick={() => {
          chat.createChatRoom(true);
        }}
      >
        + 새로운 채팅하기
      </Button>
    </p>
  );
};
