import React, { useState, useEffect, useRef } from 'react';
import { IconButton } from '../../../components/base';
//import { IinputOutput } from '../../../libs/models/studio/PluginPromptList';


interface PluginLearningSheetProps {
  initialData: string[][];
  editMode: boolean;
  onChange: (file: string[][]) => void;
}


const PluginLearningSheet: React.FC<PluginLearningSheetProps> = ({ initialData, editMode = false, onChange }) => {
  const [tableData, setTableData] = useState<string[][]>(initialData);
  const [columnWidths, setColumnWidths] = useState<number>(0);
  const focusDiv = useRef<HTMLDivElement | null>(null);
  const focusableCells = useRef<HTMLDivElement[]>([]);
  const [focusCell, setFocusCell] = useState<number[]>([1, 1]);

  // 초기 데이터가 변경될 때 테이블 데이터 업데이트
  useEffect(() => {
    if(initialData.length > 0){
      const maxWidths = initialData[0].map((_) => {
        return Math.max(
          ...initialData.map(row => row?.length)
        );
      });
      const maxWidth = Math.max(...maxWidths);
      setColumnWidths(maxWidth);
      
      const data = new Array(initialData.length).fill('').map(()=> new Array(maxWidth).fill('') as string[]);
      initialData.map((row, i)=>{
        row.map((cell, cellIndex) => {
          data[i][cellIndex] = cell;    
        })
      })
      setTableData(data);
    }else{
      setTableData([]);
    }
    
    
    focusableCells.current = [];
  }, [initialData]);

  // 컬럼 너비 계산
  // useEffect(() => {
  //   if (tableData.length > 0) {
  //     const maxWidths = tableData[0].map((_) => {
  //       return Math.max(
  //         ...tableData.map(row => row?.length)
  //       );
  //     });
  //     const maxWidth = Math.max(...maxWidths);
  //     setColumnWidths(maxWidth);
  //   }
  // }, [tableData]);

  // 셀 내용 변경 처리
  const handleCellChange = (rowIndex: number, cellIndex: number, event: React.ChangeEvent<HTMLDivElement>) => {
    const newValue = event.target.textContent;
    const newData: string[][] = tableData;
    if (newValue) newData[rowIndex][cellIndex] = newValue;
    console.log(newData);
    onChange?.(newData);
  };

  // 행 추가
  const handleAddRow = (rowIndex: number) => {
    const newRow = Array(tableData[0].length).fill(" ");
    newRow[0] = rowIndex + 1;
    const newData: string[][] = [
      ...tableData.slice(0, rowIndex + 1).map((data, index) => {
        if (index == 0) return data;
        data[0] = (index).toString(); return data;
      }),
      newRow,
      ...tableData.slice(rowIndex + 1).map((data: string[], index: number) => { data[0] = ((newRow[0] + index + 1) as number).toString(); return data })
    ] as string[][];
    onChange?.(newData);
  };

  // 행 삭제
  const handleDeleteRow = (rowIndex: number) => {
    if (rowIndex === 0) return;
    //const newData = tableData.filter((_, index) => index !== rowIndex);
    const newData = [
      ...tableData.slice(0, rowIndex),
      ...tableData.slice(rowIndex + 1).map((data, index) => { data[0] = (rowIndex + index).toString(); return data; })
    ];
    onChange?.(newData);
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const currentTextFocus: number = window.getSelection()?.focusOffset ?? 0;
    switch (event.key) {
      case 'ArrowDown':
        if (focusCell[0] + 1 >= tableData.length) return;
        focusableCells.current[(focusCell[0] + 1) * columnWidths + focusCell[1]]?.focus();
        event.preventDefault();

        break;

      case 'ArrowUp':
        focusableCells.current[(focusCell[0] - 1) * columnWidths + focusCell[1]]?.focus();
        event.preventDefault();
        break;

      case 'ArrowLeft':
        if (currentTextFocus == 0) {
          focusableCells.current[(focusCell[0]) * columnWidths + focusCell[1] - 1]?.focus();
          event.preventDefault();
        }
        break;
      case 'ArrowRight':
        if (focusCell[1] + 1 > columnWidths) return;
        const targetElement = event.target as HTMLDivElement;
        if (currentTextFocus == targetElement.innerText.length) {
          focusableCells.current[(focusCell[0] * columnWidths) + focusCell[1] + 1]?.focus();
          event.preventDefault();
        }
        break;
    }
  }

  return (
    <div className="sheet-table" onKeyDown={handleKeyDown} ref={focusDiv}>
      {tableData.map((row, rowIndex) => (
        <div className={`sheet-row ${rowIndex === 0 ? 'sheet-head' : 'sheet-data'}`} key={rowIndex}>
          {/* 셀 렌더링 */}
          {row.map((cell, cellIndex) => {
            const cellRef = (el: HTMLDivElement) => {
              if (el) {
                focusableCells.current[rowIndex * columnWidths + cellIndex] = el; // 각 셀 저장
              }
            };
            return (
              <div
                className="sheet-cell"
                key={cellIndex}
                ref={cellRef}
                contentEditable={rowIndex !== 0 && editMode && cellIndex !== 0}  // 헤더 편집 불가
                onBlur={(e) => { handleCellChange(rowIndex, cellIndex, e) }}
                suppressContentEditableWarning={true}
                style={cellIndex == 0 ? {} : {
                  width: `${columnWidths}px`,
                }}
                onFocus={() => { setFocusCell([rowIndex, cellIndex]) }}
              >
                {cell ? String(cell).trimEnd().trimStart() : ''}
              </div>
            )
          })}
          {/* {row.map((cell, cellIndex) => {
            const cellRef = (el: HTMLDivElement) => {
              if (el) {
                focusableCells.current[rowIndex * columnWidths + cellIndex] = el; // 각 셀 저장
              }
            };
            return (
              <div
                className="sheet-cell"
                key={cellIndex}
                ref={cellRef}
                contentEditable={rowIndex !== 0 && editMode && cellIndex !== 0}  // 헤더 편집 불가
                onBlur={(e) => handleCellChange(rowIndex, cellIndex, e)}
                suppressContentEditableWarning={true}
                style={cellIndex == 0 ? {} : {
                  width: `${columnWidths}px`,
                }}
                onFocus={() => setFocusCell([rowIndex, cellIndex])}
              >
                {String(cell).trimEnd().trimStart()}
              </div>
            )
          })} */}
          {/* 헤더가 아닌 행에만 컨트롤 버튼 표시 */}
          {rowIndex !== 0 && editMode && (
            <div className="sheet-ctrl">
              {/* <IconButton name="rowmove" size="large">행 이동</IconButton> */}
              <IconButton name="rowadd" size="large" onClick={() => { handleAddRow(rowIndex) }}>행 추가</IconButton>
              <IconButton name="rowdel" size="large" onClick={() => { handleDeleteRow(rowIndex) }}>행 삭제</IconButton>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PluginLearningSheet;