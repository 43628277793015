import { IconButton } from './IconButton';
import classNames from 'classnames';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button } from './Button';
import { Icon } from './Icon';
import { Loading } from './Loading';
import { Text } from './Text';

interface FileListProps {
  children: React.ReactNode;
  variant?: string;
}

interface FileType {
  name: string;
  size: number;
  type: string;
  status: 'uploading' | 'uploaded' | 'uploadFailed' | 'learning' | 'learningSuccess' | 'learningFailed';
  originalFile?: File;
}

export interface FileUploaderHandle {
  handleDelete: (file: FileType) => void;
}

const FileList: React.FC<FileListProps> = ({ children, variant }) => (
  <ul className={classNames('dsx-FileList', variant && `dsx-FileList--${variant}`)}>{children}</ul>
);

interface FileListItemProps {
  files: File[];
  onDownload?: (file: File) => string;
  onDelete: (file: File) => void;
  isLoading?: boolean;
}

const FileListItem: React.FC<FileListItemProps> = ({ files, onDownload, onDelete, isLoading }) => (
  <>
    {files.map((file, index) => (
      <li className="dsx-FileList-item" key={index}>
        <a
          href={onDownload ? onDownload(file) : URL.createObjectURL(file)}
          className="dsx-FileList-link"
          download={file.name}
        >
          <Icon name="download" />
          <span className="file-name">{file.name}</span>
          <span className="file-size">&#91;{bytesToSize(file.size)}&#93;</span>
        </a>
        <div className="dsx-FileList-upload">
          {isLoading ? (
            <Loading size="1" />
          ) : (
            <>
              <Icon name="successFill" className="upload-success" />
              <IconButton
                name="closeFill"
                className="upload-delete"
                onClick={() => {
                  onDelete(file);
                }}
              >
                파일삭제
              </IconButton>
            </>
          )}
        </div>
      </li>
    ))}
  </>
);

// bytesToSize function
const bytesToSize = (bytes: number, decimal = 1): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'N/A';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(decimal)} ${sizes[i]}`;
};

// checkFileType function
const checkFileType = (files: File[], allowFileType: string[]): boolean => {
  return files.every((file) => {
    const lastIndex = file.name.lastIndexOf('.');
    if (lastIndex === -1) {
      return false;
    }
    const fileType = file.name.substring(lastIndex, file.name.length).toLowerCase();
    return allowFileType.includes(fileType);
  });
};

// Interface for FileUploader Props
interface FileUploaderProps {
  uploadMsg?: string | React.ReactNode;
  fileTypeMsg?: string;
  errMsg?: string;
  disabled?: boolean;
  allowDrop?: boolean;
  maxFiles?: number;
  maxFileSize?: number;
  allowFileType?: string[];
  defaultFileList?: File[];
  onChange?: (files: File[]) => void;
  onUpload?: (files: File[]) => void;
  onDownload?: (file: File) => string;
  onRemove?: (file: FileType) => void;
  onDrag?: (isDragging: boolean) => void;
  hideFileList?: boolean;
  buttonProps?: {
    variant?: 'primary' | 'secondary' | 'outline' | 'ghost' | 'negative' | 'normal' | 'text';
    size?: 'small' | 'default' | 'large';
    text?: React.ReactNode;
    prefixIcon?: string;
  };
  addControls?: React.ReactNode;
}

// FileUploader component
export const FileUploader = forwardRef<FileUploaderHandle, FileUploaderProps>(
  (
    {
      uploadMsg = 'Drop files to upload',
      fileTypeMsg,
      errMsg,
      disabled,
      allowDrop,
      maxFiles = 1,
      maxFileSize = 10485760,
      allowFileType = [
        '.xlsx',
        '.xls',
        '.docx',
        '.doc',
        '.pptx',
        '.ppt',
        '.txt',
        '.pdf',
        '.zip',
        '.jpg',
        '.jpeg',
        '.png',
        '.gif',
      ],
      defaultFileList = [],
      onChange,
      onUpload,
      onDownload,
      onRemove,
      onDrag,
      hideFileList,
      buttonProps,
      addControls,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectFiles, setSelectFiles] = useState<File[]>([]);
    const [uploadFileList, setUploadFileList] = useState<File[]>(defaultFileList);
    const [invalidMsg, setInvalidMsg] = useState<string>();
    const [fileLength, setFileLength] = useState<number>(defaultFileList.length);
    const [dragging, setDragging] = useState<boolean>(false);

    const onClickFileUpload = () => {
      inputRef.current?.click();
    };

    const checkFileValid = (files: File[]): boolean => {
      if (files.length <= 0) return false;
      if (!checkFileType(files, allowFileType)) {
        setInvalidMsg(`${allowFileType.join(',')} 파일만 업로드 가능합니다.`);
        return false;
      }
      if (maxFiles > 1 && fileLength + files.length > maxFiles) {
        setInvalidMsg(`${maxFiles}개의 파일만 업로드 가능합니다.`);
        return false;
      }
      if (files.some((file) => file.size > maxFileSize)) {
        setInvalidMsg('파일 사이즈를 확인하세요.');
        return false;
      }
      return true;
    };

    const handleOnChange = (files: FileList | null): void => {
      if (!files) return;
      const uploadFiles = Array.from(files);
      if (!checkFileValid(uploadFiles)) return;

      setInvalidMsg(undefined);
      setFileLength((prev) => prev + uploadFiles.length);

      // const uniqueFiles = uploadFiles.filter(
      //   (file) => !uploadFileList.some((existingFile) => existingFile.name === file.name),
      // );
      const uniqueFiles = uploadFiles; // 모든 파일을 그대로 업로드 (삭제 후 재 업로드 허용)

      if (onUpload) {
        setSelectFiles((prev) => [...prev, ...uniqueFiles]);
        try {
          onUpload(uniqueFiles);
          setUploadFileList((prev) => [...prev, ...uniqueFiles]);
          setSelectFiles((prev) => prev.filter((file) => !uniqueFiles.includes(file)));
        } catch (e) {
          setInvalidMsg(errMsg);
          setSelectFiles((prev) => prev.filter((file) => !uniqueFiles.includes(file)));
          setFileLength((prev) => prev - uniqueFiles.length);
        }
      } else {
        setUploadFileList((prev) => [...prev, ...uniqueFiles]);
      }

      if (onChange) {
        onChange(uniqueFiles);
      }
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    const onDragOver = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setDragging(true);
      if (onDrag) onDrag(true);
    };

    const onDragLeave = (e: React.DragEvent) => {
      if (e.currentTarget.contains(e.relatedTarget as Node)) return;
      setDragging(false);
      if (onDrag) onDrag(false);
    };

    const onDrop = (e: React.DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      handleOnChange(e.dataTransfer.files);
      setDragging(false);
      if (onDrag) onDrag(false);
    };
    const handleDelete = (file: FileType) => {
      // File 타입 사용
      setInvalidMsg(undefined);
      if (onRemove) {
        try {
          onRemove(file);
          const newList = uploadFileList.filter((existingFile) => existingFile.name !== file.name);
          setUploadFileList(newList);
          setFileLength((prev) => prev - 1);
        } catch (e) {
          setInvalidMsg(errMsg);
        }
      } else {
        const newList = uploadFileList.filter((existingFile) => existingFile.name !== file.name);
        setUploadFileList(newList);
        setFileLength((prev) => prev - 1);
      }

      // 파일 삭제 후 input 필드를 초기화하여 같은 파일을 다시 업로드할 수 있도록 함
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    };

    useImperativeHandle(ref, () => ({
      handleDelete: handleDelete,
    }));

    return (
      <div className="dsx-FileUpload">
        <div
          className={classNames('dsx-File', allowDrop && 'dsx-File--drag')}
          onDragOver={allowDrop ? onDragOver : undefined}
          onDragLeave={allowDrop ? onDragLeave : undefined}
          onDrop={allowDrop ? onDrop : undefined}
          data-drag={allowDrop ? dragging : undefined}
        >
          <input
            disabled={disabled}
            ref={inputRef}
            type="file"
            className="dsx-File-input"
            multiple={maxFiles > 1}
            hidden
            onChange={(e) => {
              handleOnChange(e.target.files);
            }}
            accept={allowFileType.join(',')}
          />
          {allowDrop &&
            (typeof uploadMsg === 'string' ? (
              <Text size="body1" weight="semibold">
                {uploadMsg}
              </Text>
            ) : (
              uploadMsg
            ))}
          <Button
            variant={buttonProps?.variant?? ( allowDrop ? 'primary' : 'secondary') } 
            size={buttonProps?.size}
            {...(!allowDrop && { prefixIcon: 'plusFill' })}
            onClick={onClickFileUpload}
            disabled={disabled}
          >
            {buttonProps && buttonProps.text ? buttonProps.text : '파일 선택'}
          </Button>
          {!allowDrop && fileTypeMsg && (
            <Text size="caption1" className="dsx-color-primary">
              {fileTypeMsg && (
                <>
                  *{fileTypeMsg}
                  <br />
                </>
              )}
              {/* {maxFileSize && <>*용량 {bytesToSize(maxFileSize, 0)}</>} */}
            </Text>
          )}
          {addControls && <div className="dsx-File-controls">{addControls}</div>}
        </div>
        {!hideFileList && fileLength > 0 && (
          <FileList>
            <FileListItem
              files={uploadFileList}
              onDownload={onDownload}
              onDelete={(file) => {
                console.log(file);
                // void handleDelete(file);
              }}
            />
            <FileListItem
              files={selectFiles}
              onDownload={onDownload}
              onDelete={(file) => {
                console.log(file);
                // void handleDelete(file);
              }}
              isLoading
            />
          </FileList>
        )}
        {errMsg && <Text variant="error">{errMsg}</Text>}
        {invalidMsg && <Text variant="error">{invalidMsg}</Text>}
      </div>
    );
  },
);

FileUploader.displayName = 'FileUploader';

export default FileUploader;
