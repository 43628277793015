import classNames from 'classnames';
import { useState } from 'react';
import { Icon } from './Icon';
import { IconButton } from './IconButton';

interface TagProps extends React.HTMLAttributes<HTMLSpanElement> {
  variant?: string;
  icon?: string;
  label: string;
  onRemove?: () => void;
  className?: string;
  closable?: boolean;
}

export const Tag: React.FC<TagProps> = ({ variant, icon, label, onRemove, className, closable = false, ...props }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleRemove = () => {
    if (onRemove) {
      onRemove();
    } else {
      setIsVisible(false);
    }
  };

  if (!isVisible) return null;

  return (
    <span className={classNames('dsx-Tag', variant && `dsx-Tag--${variant}`, className)} {...props}>
      {icon && <Icon name={icon} />}
      <span className="dsx-Tag-name">{label}</span>
      {closable && (
        <IconButton name="close" onClick={handleRemove}>
          삭제
        </IconButton>
      )}
    </span>
  );
};
