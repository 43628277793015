import { useState, useRef, forwardRef, ChangeEvent } from 'react';
import { Radio } from './Radio';
import classNames from 'classnames';

interface RadioOption {
  value: string;
  label: string;
  disabled?: boolean;
}

//extends HTMLProps<HTMLDivElement>
interface RadioGroupProps {
  name?: string;
  value?: string;
  defaultValue?: string;
  options: RadioOption[];
  invalid?: boolean;
  size?: string;
  vertical?: boolean;
  className?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>, value: string) => void;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (
    { name, value: valueProp, defaultValue, options, invalid, size = 'large', vertical, className, onChange, ...props },
    ref,
  ) => {
    const [selectedValue, setSelectedValue] = useState<string>(defaultValue ?? '');
    const { current: controlled } = useRef<boolean>(typeof valueProp !== 'undefined');
    const internalValue = controlled ? valueProp : selectedValue;

    const groupName = useRef<string>(name ?? `radio-group-${Math.random().toString(36).substr(2, 6)}`).current;

    const internalChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (!controlled) {
        setSelectedValue(event.target.value);
      }
      if (onChange) {
        onChange(event, event.target.value);
      }
    };

    return (
      <div
        ref={ref}
        role="radiogroup"
        className={classNames('dsx-RadioGroup', vertical && 'dsx-align-vertical', className)}
        {...props}
      >
        {options.map((option) => (
          <Radio
            key={option.value}
            size={size}
            name={groupName}
            value={option.value}
            checked={option.value === internalValue}
            onChange={internalChange}
            disabled={option.disabled}
            invalid={invalid}
          >
            {option.label}
          </Radio>
        ))}
      </div>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';
