import React, { useEffect, useState } from 'react';
import worksSymbol from '../../../assets/images/logo_works_symbol.svg';
import { Empty, Heading, Text, Avatar, Icon } from '../../../components/base';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { ChatMessageType, IChatMessage } from '../../../libs/models/conversations/ChatMessage';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import { useChat } from '../../../libs/hooks/useChat';
import { IAskVariables } from '../../../libs/semantic-kernel/model/Ask';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { checkDownloadUrl } from '../../chat/chat-history/ChatHistoryTextContent';

interface PromptCreatePreviewProps {
  testResult: number;
  formData?: IPluginPromptData;
  setTestResult: (value: number | ((prev: number) => number)) => void;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
}

interface GetResponseOptions {
  messageType: ChatMessageType;
  value: string;
  chatId: string;
  model: string;
  kernelArguments?: IAskVariables[];
  processPlan?: boolean;
  cacheMode?: boolean;
}

const PromptCreatePreview: React.FC<PromptCreatePreviewProps> = ({
  testResult,
  formData,
  setTestResult,
  setFormData,
}) => {
  const { user } = useAppSelector((state: RootState) => state.users);
  const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
  const dispatch = useAppDispatch();
  const chat = useChat();
  const [responseMessage, setResponseMessage] = useState('');
  const [requestMessage, setRequestMessage] = useState<string>('');
  const [chatVisible, setChatVisible] = useState(false);

  const messages = React.useMemo(() => {
    return selectedId === ''
      ? { data: [] as IChatMessage[], continuationToken: null as string | null }
      : conversations[selectedId].messages;
  }, [conversations, selectedId]);

  useEffect(() => {
    if (!formData?.promptTemplate) {
      return;
    }
    if (testResult > 0) {
      setChatVisible(true);
      setResponseMessage('');
      setRequestMessage(formData.promptTemplate);
      sendTestPromptMessage(formData.promptTemplate);
    }
  }, [testResult]);

  // 최초 렌더링 시에만 requestMessage 설정
  useEffect(() => {
    if (formData?.promptTemplate && !requestMessage) {
      setRequestMessage(formData.promptTemplate);
    }
  }, [formData, requestMessage]);

  useEffect(() => {
    if (messages.data.length > 1 && messages.data[1]?.messages?.length > 0) {
      const maxCount = messages.data.length - 1;
      setResponseMessage(messages.data[maxCount].messages[0].message);
    } else {
      setResponseMessage('');
    }
    setFormData((prev) => ({ ...prev, promptTemplateResponse: responseMessage }));
  }, [messages]);

  const sendTestPromptMessage = (value: string | undefined) => {
    if (!value) {
      return; // only submit if value is not empty
    }

    const options = {
      value: value,
      messageType: ChatMessageType.ChatMessage,
      chatId: formData?.id ?? '1',
      model: formData?.model ?? 'default_model',
      cacheMode: true,
    };
    const encoder = new TextEncoder();
    const length = encoder.encode(JSON.stringify(options));

    if (length.byteLength > 30000) {
      dispatch(
        showAlertToast({
          altMessage: '작성한 내용이 너무 길어요. 수정한 후 다시 시도해주세요.',
          altType: AlertType.Info,
        }),
      );
      return;
    }
    handleSubmit(options);
  };

  const handleSubmit = (options: GetResponseOptions) => {
    try {
      const createChatResult = chat.createTestChat(options.chatId, user.userId); // Ensure it's awaited properly
      if (createChatResult) {
        chat.getResponse(options); // Ensure it's awaited properly
      }
    } catch (err) {
      console.error(err); // Logging error safely
    }
  };

  const resetConversation = () => {
    setChatVisible(false);
    setResponseMessage('');
    setRequestMessage('');
    setFormData((prev) => ({ ...prev, promptTemplateResponse: '' }));
    setTestResult((prev) => prev++);
  };

  return (
    <>
      {testResult > 0 || formData?.promptTemplateResponse ? (
        <div className="view-info view-info--primary">
          <Heading size="body2" weight="semibold">
            프롬프트 템플릿 테스트
          </Heading>
          <Text size="label2">
            프롬프트 템플릿 테스트를 진행해 주세요. 해당 내용은 추후 프롬프트 소개 페이지에서 예시로 사용됩니다.
          </Text>
        </div>
      ) : (
        <div className="view-info">
          <Heading size="body2" weight="semibold">
            프롬프트 템플릿 테스트
          </Heading>
          <Text size="label2">프롬프트 입력 후 프롬프트 템플릿 테스트가 가능합니다.</Text>
        </div>
      )}
      <div className="view-chat">
        {chatVisible || formData?.promptTemplateResponse ? (
          <section className="chat-history">
            <div className="chat-history-item">
              <div className="chat-message">
                <div className="msg-profile">
                  <Avatar
                    size="2"
                    src={`https://gspaceapi.kt.co.kr/upload/profile/${user.userId}.jpg`}
                    label="msg-profile1"
                  />
                  <strong className="name">{user.name}</strong>
                </div>
                <div className="msg-content">
                  <p>{requestMessage}</p>
                </div>
              </div>
              {formData?.promptTemplateResponse && (
                <div className="chat-message">
                  <div className="msg-profile">
                    <Avatar size="2" src={worksSymbol} label="msg-profile2" />
                    <strong className="name">{formData.name}</strong>
                  </div>
                  <div className="msg-content">
                    <ReactMarkdown
                      remarkPlugins={[remarkGfm]}
                      // rehypePlugins={[rehypeHighlight]}
                      components={{
                        a: ({ children, ...props }) => {
                          if (props.href?.includes('http')) {
                            props.target = '_blank';
                            props.rel = 'noopener noreferrer';
                          }
                          const { isDownloadUrl, url } = checkDownloadUrl(props.href);
                          if (isDownloadUrl && url) {
                            props.target = '_blank';
                            props.rel = 'noopener noreferrer';
                            props.href = url;
                            props.className = 'msg-caution ds-link no-click';
                            children = children?.toString().replace('[', '').replace(']', '');
                          }
                          return <a {...props}>{children}</a>;
                        },
                        // 'del' 태그를 span 태그로 대체하여 취소선을 제거
                        del: ({ ...props }) => <span>{props.children}</span>,
                        table: ({ ...props }) => (
                          <div className="msg-table">
                            <table>{props.children}</table>
                          </div>
                        ),
                      }}
                    >
                      {formData.promptTemplateResponse.replace(/\\n/g, '<br>')}
                    </ReactMarkdown>
                  </div>
                </div>
              )}
            </div>
          </section>
        ) : (
          <Empty
            image="/src/assets/images/ico_load.svg"
            desc={
              <>
                왼쪽 화면에서
                <br />
                프롬프트 템플릿 생성을 완료해 주세요!
              </>
            }
          />
        )}
        <div className="chat-bottom">
          <div className="chat-info">
            <p className="txt-length">
              테스트 가능 횟수{' '}
              <span>
                <b className="dsx-color-primary">{10 - testResult}</b>/10
              </span>
            </p>
            <button type="button" className="btn-reset" onClick={resetConversation}>
              대화 초기화
            </button>
          </div>
          <div className="chat-warning">
            <Icon name="infoFill" />
            답변에 잘못된 정보가 있을 수 있습니다. 중요한 정보는 확인해 주세요.
          </div>
        </div>
      </div>
    </>
  );
};

export default PromptCreatePreview;
