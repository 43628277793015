import classNames from 'classnames';
import React, { ElementType, forwardRef } from 'react';
import Badge from './Badge';
import { Icon } from './Icon';

interface BadgeProps {
  icon?: string;
  label?: string;
  size?: 'dot' | 'xsmall' | 'small' | 'medium' | 'xlarge' | 'xxlarge'; // Badge 컴포넌트에서 사용하는 타입으로 정의
}

interface AvatarProps {
  as?: 'span' | 'button'; // HTML 요소 타입
  variant?: 'default' | 'ring' | 'empty' | 'user';
  accent?: 'primary' | 'positive' | 'negative' | 'caution' | 'info' | 'normal';
  invalid?: boolean;
  size?: '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';
  icon?: string;
  src?: string | null;
  label: string;
  disabled?: boolean;
  badgeProps?: BadgeProps;
  children?: React.ReactNode;
  onClick?: () => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  userAvatar?: boolean;
  className?: string;
  largeUserAvatar?: boolean;
}

export const Avatar = forwardRef<HTMLButtonElement | HTMLSpanElement, AvatarProps>(
  (
    {
      as = 'span',
      variant = 'default',
      size = '2',
      src,
      label,
      icon,
      disabled,
      accent,
      invalid,
      children,
      badgeProps,
      onClick,
      onChange,
      userAvatar = false,
      className,
      largeUserAvatar = false,
      ...props
    },
    ref,
  ) => {
    const Tag: ElementType = as;

    const getBadgeSize = (avatarSize: string): 'dot' | 'xsmall' | 'small' | 'medium' | 'xlarge' | 'xxlarge' => {
      switch (avatarSize) {
        case '1':
        case '2':
          return 'dot';
        case '3':
          return 'xsmall';
        case '4':
        case '5':
          return 'small';
        case '6':
        case '7':
          return 'medium';
        case '8':
          return 'xlarge';
        case '9':
          return 'xxlarge';
        default:
          return 'small';
      }
    };

    const badgeSize = badgeProps?.size ?? getBadgeSize(size);

    return (
      <Tag
        className={
          userAvatar
            ? largeUserAvatar
              ? classNames('ds-avatar', 'user-avatar', 'large')
              : classNames('ds-avatar', 'user-avatar')
            : classNames(
                'dsx-Avatar',
                variant !== 'default' && `dsx-Avatar--${variant}`,
                `dsx-Avatar--size${size}`,
                invalid && 'is-invalid',
                className,
              )
        }
        ref={(el) => {
          if (as === 'button' && ref && typeof ref === 'function') {
            (ref as (instance: HTMLButtonElement | null) => void)(el as HTMLButtonElement);
          } else if (as === 'span' && ref && typeof ref === 'function') {
            (ref as (instance: HTMLSpanElement | null) => void)(el as HTMLSpanElement);
          }
        }}
        {...(icon && { 'aria-label': label })}
        {...(as === 'button' && { disabled })}
        {...(accent && { 'data-accent': accent })}
        onClick={onClick}
        {...props}
      >
        {as === 'button' && (
          <input
            type="file"
            onChange={onChange} // input에 onChange 연결
            style={{ display: 'none' }} // 숨겨진 input
          />
        )}
        {src ? (
          <img
            src={src}
            className="dsx-Avatar-image"
            alt={label || ''}
            onError={(e) => {
              const parentSpan = e.currentTarget.parentElement;
              if (parentSpan) {
                parentSpan.style.background = 'var(--ds-icon-profile-fill) 50% 50% no-repeat';
                parentSpan.style.backgroundSize = '100%';
              }
              e.currentTarget.style.display = 'none';
            }}
            onLoad={(e) => {
              e.currentTarget.style.display = '';
              const parentSpan = e.currentTarget.parentElement;
              if (parentSpan) {
                parentSpan.style.background = '';
                parentSpan.style.backgroundSize = '';
              }
            }} // Reset error state on successful load
          />
        ) : icon ? (
          <Icon name={icon} />
        ) : (
          label
        )}
        {badgeProps && (
          <Badge variant="ring" accent={accent} iconOnly={badgeProps.icon} position="bottomEnd" size={badgeSize}>
            {badgeProps.label}
          </Badge>
        )}
        {children}
      </Tag>
    );
  },
);

Avatar.displayName = 'Avatar';

export default Avatar;
