import { useEffect, useRef, useState } from 'react';
import { IconUpload } from '../../../assets/icons';
import {
  Badge,
  Button,
  ButtonArea,
  Form,
  FormItem,
  Heading,
  IconButton,
  Text,
  Icon,
  FileUploader,
  FileUploaderHandle,
} from '../../../components/base';
import Image from '../../../components/Image';
//import { useAlert } from '../../../context/AlertContext';
import { bytesToSize, getFileIcon } from '../../../libs/common';
import { usePlugin } from '../../../libs/hooks/usePlugin';
import { AlertType } from '../../../libs/models/AlertType';
import { IApiResult } from '../../../libs/models/app/APIResult';
import {
  ECreatedStatusType,
  IFileData,
  IPluginContentsSave,
  IPluginFileSave,
  IPluginPromptData,
  RagContentsData,
} from '../../../libs/models/studio/PluginPromptList';
import { useAppDispatch } from '../../../redux/app/hooks';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import PluginLearningSheet from './PluginLearningSheet';
import { useAlert } from '../../../context/AlertContext';

interface IPluginCreateDtl {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onPrev: () => void;
  onNext: () => void;
}

interface FileType {
  name: string;
  size: number;
  type: string;
  status: 'uploading' | 'uploaded' | 'uploadFailed' | 'learning' | 'learningSuccess' | 'learningFailed' | 'removing';
  originalFile?: File;
}


// [File Uploader - Fake] 파일 업로드 & 러닝 가상 시뮬레이션
const templateStatusToKorean: { [key in RagContentsData['uploadStatus']]: string } = {
  NotStarted: '',
  Pending: '업로드 대기 중',
  InProgress: '업로드 중',
  Completed: '업로드 완료',
  Failed: '업로드 실패',
  Canceled: '업로드 취소',
  Removing: '삭제 중',
  Learning: '학습 중',
  LearningSuccess: '학습 성공',
  LearningFailed: '학습 실패',
  Save: '저장 완료',
  Saving: '저장 중',
  Editing: '수정 중'
};

// [File Uploader - Fake] 파일 업로드 & 러닝 가상 시뮬레이션
const statusToKorean: { [key in FileType['status']]: string } = {
  uploading: '업로드중',
  uploaded: '업로드 완료',
  uploadFailed: '업로드 실패',
  learning: '학습중',
  learningSuccess: '학습 성공',
  learningFailed: '학습 실패',
  removing: '삭제중',
};

const PluginCreateDtl: React.FC<IPluginCreateDtl> = ({ formData, setFormData, onPrev, onNext }) => {
  const plugin = usePlugin();
  const dispatch = useAppDispatch();
  //const { showAlert } = useAlert();

  //const [ragContentsData, setRagContentsData] = useState<RagContentsData>(formData.ragContentsData ?? );
  // [File Uploader]
  const fileRef = useRef<FileUploaderHandle | null>(null);

  const templateRef = useRef<FileUploaderHandle | null>(null);
  const [templateFile, setTemplateFile] = useState<RagContentsData>({ id: '', contentsDataFileName: '', uploadStatus: 'NotStarted' });
  // const [tableData, setTableData] = useState<IinputOutput[][]>([])
  const [tableData, setTableData] = useState<string[][]>([]);
  const [templateEditMode, setTemplateEditMode] = useState(false);

  const [files, setFiles] = useState<FileType[]>([]);
  const [dragging, setDragging] = useState(false);

  const { showAlert } = useAlert();

  useEffect(() => {
    setFiles(
      (formData.ragFiles ?? []).map((ragFile: IFileData) => ({
        name: ragFile.fileName,
        size: ragFile.fileSizeLong ?? 0,
        type: ragFile.fileName,
        status: ragFile.hasError ? 'learningFailed' : ragFile.isCompleted ? 'learningSuccess' : 'uploading', // isCompleted 가 true 면 학습완료, false 면 업로드중
      })),
    );
    if (formData.ragContentsData) {
      if (formData.ragContentsData.contentsData) {
        const pattern2 = /\n(?=(?:(?:[^"]*"[^"]*")*[^"]*$))/;
        const pattern =/,(?=(?:(?:[^"]*"[^"]*")*[^"]*$))/;
        const rows: string[] = formData.ragContentsData.contentsData.trim().split(pattern2);
        const csvData: string[][] = rows.map(row => row.split(pattern));
        setTableData(csvData);
        
      }
      setTemplateFile(formData.ragContentsData);
    }

  }, []);

  useEffect(() => {
    if (tableData) {
      // setRagContentsData(formData.ragContentsData);
      const content = tableData.map((row) => {
        return row.join(',')
      }).join('\n');
      setTemplateFile((prev) => ({ ...prev, contentsData: content }));
    }
  }, [tableData]);

  useEffect(() => {
    setFormData((prev)=>({
      ...prev,
      ragContentsDataId: templateFile.id,
      ragContentsData: templateFile
    }))
  }, [templateFile]);

  const handlePrev = () => {
    onPrev();
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    //if (validate()) onNext();
    if (validate()) onNext();
  };


  const validate = () => {
    if ((templateFile && !(templateFile.uploadStatus == 'LearningSuccess' || templateFile.uploadStatus == 'NotStarted')) || (files.length > 0 && files.filter((file) => file.status != 'learningSuccess').length > 0)) {
      showAlert({
        title: '아직 학습되지 않은 데이터가 있습니다.',
        message:
          '다음 단계로 넘어가시겠습니까?',
        confirmLabel: '다음 단계로',
        showCancel: true,
        onConfirm: () => {
          onNext();
        },
      });
      return false;
    }
    else return true;
  }

  const fileChange = async (newFiles: File[]) => {
    const updatedFiles = Array.from(newFiles).map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      status: 'uploading' as const, // 초기상태
      originalFile: file,
    }));

    setFiles((prev) => {
      const prevFilesMap = new Map(prev.map((file) => [file.name, file]));
      updatedFiles.forEach((updatedFile) => {
        if (prevFilesMap.has(updatedFile.name)) {
          prevFilesMap.delete(updatedFile.name);
        }
        prevFilesMap.set(updatedFile.name, updatedFile);
      });
      return Array.from(prevFilesMap.values());
    });

    for (const updatedFile of updatedFiles) {
      await onFileUpload(updatedFile);
    }
  };

  const fileDelete = (file: FileType): void => {
    setFiles((prev) => prev.filter((f) => f.name !== file.name));
  };

  const handleFileRemove = async (file: FileType, idx: number): Promise<void> => {
    const customPluginId = formData.id as string;
    const documentId: string = formData.ragFileIds?.[idx] ?? '';

    setFiles((prev) => prev.map((f) => (f.name === file.name ? { ...f, status: 'removing' } : f)));

    try {
      // 파일 삭제
      await plugin.deletePluginRagFile(customPluginId, documentId);
      setFiles((prev) => prev.filter((f) => f.name !== file.name));
    } catch (e) {
      dispatch(
        showAlertToast({
          altMessage: '파일 삭제에 실패했습니다. 잠시 후 다시 시도해 주세요.',
          altType: AlertType.Info,
        }),
      );
    }

    setFormData((prev) => ({
      ...prev,
      ragFileIds: prev.ragFileIds?.filter((id) => id !== documentId),
      ragFiles: prev.ragFiles?.filter((file) => file.documentId !== documentId),
    }));
  };

  const checkFileUploadCompleted = () => {
    if (formData.ragFiles && formData.ragFiles.length > 0) {
      // 모든 파일이 완료되었고, 에러가 없는지 확인
      const allCompleted = formData.ragFiles.every((ragfile) => ragfile.isCompleted && !ragfile.hasError);

      // 하나라도 학습 중이거나 에러가 있으면 학습 중으로 상태 변경
      if (allCompleted) {
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.LearningComplete, // 모든 파일 학습 완료
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.Learning, // 학습 중
        }));
      }
    }
  };

  const onFileUpload = async (file: FileType) => {
    setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learning' } : f)));

    // 폼 데이터를 학습 중으로 상태 변경
    setFormData((prev) => ({
      ...prev,
      createdStatus: ECreatedStatusType.Learning, // 학습 중
    }));

    const customPluginId = formData.id as string;
    const notDownloadable = false;
    const isDraft = formData.versionStatus === 'Draft';

    try {
      // 파일 업로드
      const res: IPluginFileSave = await plugin.postPluginRagFile(
        { customPluginId, notDownloadable, isDraft },
        file.originalFile as File,
      );

      if (res.value.status === 'Error') {
        setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learningFailed' } : f)));
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.Registration, // 등록중
        }));
      } else if (!res.value.hasError) {
        // 업로드 성공 시 상태를 'uploaded'로 설정
        // setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'uploaded' } : f)));

        // 학습 상태로 변경
        // setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learning' } : f)));

        // 학습 성공 시 상태를 'learningSuccess'로 설정
        setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learningSuccess' } : f)));

        // 첨부파일 id, files 값 셋팅
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.LearningComplete, // 학습완료
          ragFileIds: Array.from(new Set([...(prev.ragFileIds ?? []), res.value.documentId])),
          ragFiles: Array.from(new Set([...(prev.ragFiles ?? []), res.value])),
        }));
      } else {
        setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'learningFailed' } : f)));
        setFormData((prev) => ({
          ...prev,
          createdStatus: ECreatedStatusType.Learning, // 학습중
        }));
      }

      // 업로드 완료 여부 확인
      checkFileUploadCompleted();
    } catch (error: unknown) {
      // 업로드 실패 시 상태를 'uploadFailed'로 설정
      setFiles((prevFiles) => prevFiles.map((f) => (f.name === file.name ? { ...f, status: 'uploadFailed' } : f)));
      handleError(error);
    }
  };
  const ERROR_MESSAGES: Record<string, string> = {
    DRM: '문서DRM이 적용된 문서의 경우 DRM해제 후 업로드해주세요.',
    NotSupported: '지원되지 않는 확장자입니다.',
    'Payload Too Large': '파일 용량이 허용 용량을 초과하였습니다. 용량을 확인해주세요.',
    Default: '파일 업로드가 실패하였습니다. 사내 보안 정책에 따라 파일 업로드가 차단될 수 있습니다.',
  };

  const handleError = (error: unknown) => {
    const apiError = error as IApiResult;

    const message = apiError.status in ERROR_MESSAGES ? ERROR_MESSAGES[apiError.status] : ERROR_MESSAGES.Default;
    dispatch(
      showAlertToast({
        altMessage: message,
        altType: AlertType.Info,
      }),
    );
  };

  const templateFileChange = (newFiles: File[]) => {
    // 폼 데이터를 학습 중으로 상태 변경
    setFormData((prev) => ({
      ...prev,
      createdStatus: ECreatedStatusType.Registration, // 학습 중
    }));

    setTemplateEditMode(false);
    if (newFiles && newFiles.length > 0) {
      const file = Array.from(newFiles)[0];
      const newTemplateFile = {
        id: `template-${file.name}-${Date.now()}`,
        contentsDataFileName: '',
        uploadStatus: 'InProgress'
      } as RagContentsData;

      setTemplateFile(newTemplateFile);
      onTemplateFileUpload(file).then(() => {

      }).catch(() => {
        dispatch(
          showAlertToast({
            altMessage: '파일 업로드가 실패했습니다. 잠시 후 다시 시도해 주세요.',
            altType: AlertType.Info,
          }),
        );
      });
    }
  };
  // 템플릿 파일 삭제 핸들러
  const templateFileDelete = () => {
    setTemplateFile({ id: '', contentsDataFileName: '', type: '', uploadStatus: 'Removing' });
    plugin.deleteRagContentsData(templateFile).then(() => {
      setTemplateFile({ id: '', contentsData: '', contentsDataFileName: '', type: '', uploadStatus: 'NotStarted' });
      setTableData([]);
    }).catch(() => {
      dispatch(
        showAlertToast({
          altMessage: '파일 삭제를 실패했습니다. 잠시 후 다시 시도해 주세요.',
          altType: AlertType.Info,
        }),
      );
    });

  };

  const onTemplateFileUpload = async (file: File) => {
    try {
      const result: RagContentsData = await plugin.uploadRagContentsData(file, formData.id ?? "0");
      if (result.contentsData) {
        const pattern2 = /\n(?=(?:(?:[^"]*"[^"]*")*[^"]*$))/;
        const pattern =/,(?=(?:(?:[^"]*"[^"]*")*[^"]*$))/;
        const rows: string[] = result.contentsData.trim().split(pattern2);
        const csvData: string[][] = rows.map(row => row.split(pattern));
        setTableData(csvData);

        setTemplateFile({
          ...result,
          uploadStatus: 'Completed'
        });
        setFormData((prev) => ({
          ...prev,
          ragContentsDataId: result.id,
          ragContentsData: result,
          createdStatus: ECreatedStatusType.Registration,
        }))
      }

    } catch (error) {
      const apiError = error as IApiResult;
      const message = apiError.status in ERROR_MESSAGES ? ERROR_MESSAGES[apiError.status] : ERROR_MESSAGES.Default;
      dispatch(
        showAlertToast({
          altMessage: message,
          altType: AlertType.Info,
        }),
      );

      setTemplateFile({ id: '', contentsData:'', contentsDataFileName: '', type: '', uploadStatus: 'NotStarted' });
      setTableData([]);
      setFormData((prev) => ({
        ...prev,
        ragContentsDataId: '',
        ragContentsData: null,
        createdStatus: ECreatedStatusType.Registration,
      }))
    }
  };

  const onTemplateToggle = () => {
    if (templateEditMode) void saveTemplate();
    else {
      setTemplateFile((prev) => ({
        ...prev,
        uploadStatus: 'Editing'
      }));
    }
    setTemplateEditMode(!templateEditMode);
  }

  const saveTemplate = async () => {
    setTemplateFile((prev) => ({
      ...prev,
      uploadStatus: 'Saving'
    }));
    const res: IPluginContentsSave = await plugin.saveRagContentsData(templateFile);
    if (res.value) {
      setTemplateFile((prev) => ({
        ...prev,
        uploadStatus: 'Save'
      }));

      setFormData((prev) => ({
        ...prev,
        ragContentsDataId: res.value.id,
        ragContentsData: res.value
      }));
    }
  }

  const embeddingTemplate = () => {
    try {
      setFormData((prev) => ({
        ...prev,
        createdStatus: ECreatedStatusType.Learning, // 학습 중
      }));
      setTemplateFile((prev) => ({ ...prev, uploadStatus: 'Learning' }));
      if (templateFile.customPluginId) {
        // const res:IPluginContentsSave = await plugin.embeddingRagContentsData(templateFile);
        plugin.embeddingRagContentsData(templateFile).then((res) => {
          if (res.value.status == "Error" || res.value.hasError) {
            setTemplateFile((prev) => ({
              ...prev,
              uploadStatus: 'LearningFailed'
            }));
            setFormData((prev) => ({
              ...prev,
              createdStatus: ECreatedStatusType.Registration, // 학습 중
            }));
          } else {
            setTemplateFile((prev) => ({
              ...prev,
              uploadStatus: 'LearningSuccess'
            }));
            setFormData((prev) => ({
              ...prev,
              createdStatus: ECreatedStatusType.LearningComplete, // 학습 중
            }));
          }
        }).catch((e) => {
          console.error(e);
        });

      }

    } catch {
      setTemplateFile((prev) => ({
        ...prev,
        uploadStatus: 'LearningFailed'
      }));
    }
  }

  const templateDownload = () => {
    const data = 'no, 질문, 답\n1,Works AI 담당부서, 전략AX사업본부 Biz솔루션담당 DWP개발팀';
    //const csvContent = data.replace(/\\u([0-9a-fA-F]{4})/g, (_, grp) => String.fromCharCode(parseInt(grp, 16)))
    csvfileDownload(data, '데이터간편등록 템플릿.csv');
  }

  const templateFileDownload = () => {
    if (templateFile.contentsData) {
      csvfileDownload(templateFile.contentsData, templateFile.contentsDataFileName)
    }
  }

  const csvfileDownload = (data: string, filename: string) => {
    const blob = new Blob(['\uFEFF'+data], { type: 'text/csv;charset=utf-8'});
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  return (
    <>
      <div className="dsx-title-bar">
        <Heading as="h2" size="title3" weight="semibold">
          학습 데이터 등록
        </Heading>
        <Text size="body2">파일을 첨부하거나, 데이터 간편 등록을 통해 학습 데이터를 직접 입력해 보세요.</Text>
      </div>
      <Form variant="vertical" className="form-field" onSubmit={handleFormSubmit}>
        <FormItem label="파일 첨부" desc="학습시키고 싶은 데이터가 있다면 첨부해 주세요.">
          <FileUploader
            ref={fileRef}
            allowDrop
            allowFileType={['.pdf', '.xlsx', '.jpg', '.png', '.doc', '.docx']}
            uploadMsg={
              <>
                <IconUpload size={40} variant={dragging ? 'primary' : 'normal'} />
                <Text size="body3" weight="medium">
                  내 PC에서 파일을 첨부하거나
                  <br />
                  마우스로 문서를 드래그하여 넣어주세요.
                </Text>
                <Text size="caption1" accent="alternative">
                  *25MB 이하의 PDF, XLSX, JPG, PNG, DOC, DOCX 파일만 업로드 가능합니다.
                </Text>
              </>
            }
            onChange={(files: File[]) => {
              void fileChange(files);
            }}
            onRemove={fileDelete}
            onDrag={(bool: boolean) => {
              setDragging(bool);
            }}
            buttonProps={{ variant: dragging ? 'primary' : 'secondary', text: '내 PC 파일 업로드' }}
            hideFileList
            maxFileSize={26214400}
          />
          {files.length > 0 && (
            <div className="upload-container">
              <Heading as="strong" size="body3">
                업로드 파일
              </Heading>
              <ul className="upload-fileList">
                {files.map((file, idx) => (
                  <li className="upload-fileList-item" key={idx}>
                    <span className="file-icon">
                      <Image url={getFileIcon(file.type)} />
                    </span>
                    <span className="file-name">{file.name}</span>
                    <span className="file-size">{bytesToSize(file.size)}</span>
                    <div className="upload-fileList-controls">
                      <Badge
                        variant="tint"
                        size="large"
                        round
                        accent={
                          file.status === 'uploaded' || file.status === 'learningSuccess'
                            ? 'info'
                            : file.status === 'uploadFailed' || file.status === 'learningFailed'
                              ? 'negative'
                              : 'normal'
                        }
                      >
                        {statusToKorean[file.status]}
                        {(file.status === 'uploading' || file.status === 'learning' || file.status === 'removing') && (
                          <Icon name="loading" />
                        )}
                      </Badge>

                      {file.status === 'uploading' ? (
                        <IconButton
                          name="close"
                          className="file-delete"
                          size="large"
                        // onClick={() => {
                        //   onCancelUpload(file);
                        // }}
                        >
                          업로드 취소
                        </IconButton>
                      ) : (
                        <IconButton
                          name="delete"
                          className="file-delete"
                          size="large"
                          onClick={() => {
                            void handleFileRemove(file, idx);
                          }}
                          disabled={file.status === 'learning'}
                        >
                          파일삭제
                        </IconButton>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </FormItem>
        <hr className="dsx-hr" />
        <FormItem label="템플릿 파일 다운/수정" desc="CSV 형식의 템플릿 파일만 업로드 가능하며, 파일 첨부 후 ‘학습’ 버튼을 눌러야 학습이 시작됩니다.">
          <div className="learning-upload">
            <FileUploader
              ref={templateRef}
              allowFileType={['.csv']}
              buttonProps={{ size: 'large', prefixIcon: 'plus', text: '파일 업로드' }}
              hideFileList
              onChange={templateFileChange}
              onRemove={templateFileDelete}
              addControls={
                <>
                  {/* <Button variant="secondary" size="large">XLSX 템플릿</Button> */}
                  <Button onClick={templateDownload} variant="secondary" size="large">CSV 템플릿</Button>
                </>
              }
              allowDrop={false}
            />
            {templateFile && (
              <div className="upload-fileView">
                <div className="upload-fileView-head">
                  <span className="file-name">{templateFile.contentsDataFileName}</span>
                  {/* <span className="file-size">{bytesToSize(templateFile.size)}</span> */}
                  <div className="upload-fileView-controls">
                    {templateFile.uploadStatus && templateFile.uploadStatus !== 'NotStarted' && templateFile.uploadStatus !== 'Editing' && <Badge
                      variant="tint"
                      size="large"
                      accent={
                        (templateFile.uploadStatus === 'Completed' || templateFile.uploadStatus === 'LearningSuccess' || templateFile.uploadStatus === 'Save') ? "info" :
                          (templateFile.uploadStatus === 'Failed' || templateFile.uploadStatus === 'LearningFailed' || templateFile.uploadStatus === 'Removing') ? "negative" :
                            "normal"
                      }
                      round
                    >
                      {templateStatusToKorean[templateFile.uploadStatus]}
                      {(templateFile.uploadStatus === 'InProgress' || templateFile.uploadStatus === 'Learning' || templateFile.uploadStatus === 'Removing' || templateFile.uploadStatus === 'Saving') && <Icon name="loading" />}
                    </Badge>}
                    <IconButton
                      name="download"
                      size="large"
                      onClick={templateFileDownload}
                      disabled={templateFile.uploadStatus !== 'Completed' && templateFile.uploadStatus !== 'LearningSuccess' && templateFile.uploadStatus !== 'Save'}
                    >
                      파일다운로드
                    </IconButton>
                    <IconButton
                      name="delete"
                      size="large"
                      onClick={templateFileDelete}
                      disabled={templateFile.status ? (templateFile.uploadStatus === 'InProgress' || templateFile.uploadStatus == 'Editing' || templateFile.uploadStatus == 'Saving' || templateFile.uploadStatus == 'Learning') : true}
                    >
                      파일삭제
                    </IconButton>
                    <Button
                      variant="primary"
                      disabled={(templateFile.uploadStatus !== 'Completed' && templateFile.uploadStatus !== 'LearningSuccess' && templateFile.uploadStatus !== 'LearningFailed' && templateFile.uploadStatus !== 'Save') || templateEditMode}
                      onClick={embeddingTemplate}
                    >
                      학습
                    </Button>
                    <Button
                      variant="normal"
                      disabled={templateFile.uploadStatus !== 'Completed' && templateFile.uploadStatus !== 'LearningSuccess' && templateFile.uploadStatus !== 'LearningFailed' && templateFile.uploadStatus !== 'Editing' && templateFile.uploadStatus !== 'Save'}
                      onClick={onTemplateToggle}
                    >
                      {templateEditMode ? '저장' : '수정'}
                    </Button>
                  </div>
                </div>
                <div className="upload-fileView-sheet">
                  {(templateFile.uploadStatus !== 'InProgress' && templateFile.uploadStatus !== 'Pending') && (
                    <PluginLearningSheet
                      initialData={tableData}
                      onChange={setTableData}
                      editMode={templateEditMode}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </FormItem>
      </Form>
      <ButtonArea align="end">
        <div className="dsx-side-left">
          <Button variant="outline" size="large" prefixIcon="chevronLeft" onClick={handlePrev}>
            이전
          </Button>
        </div>
        <Button type="submit" variant="primary" size="large" suffixIcon="chevron" onClick={handleFormSubmit}>
          다음
        </Button>
      </ButtonArea>
    </>
  );
};

export default PluginCreateDtl;
