import { FC, useState } from 'react';
import { useAppDispatch } from '../../../redux/app/hooks';
import { EditChatName } from '../../../components/chat/shared/EditChatName';
import { useNavigate } from 'react-router';
import { Button } from '../../../components/Button';
import classNames from 'classnames';
import { DeleteModal } from '../../../components/DeleteModal';
import { setSelectedPlugin } from '../../../redux/features/app/pluginsSlice';
import { Tooltip } from '../../../components/Tooltip';
import { Popover } from '../../../components/base';

interface IChatListItemProps {
  id: string;
  name: string;
  timestamp: Date;
  isSelected: boolean;
}

export const ChatListItem: FC<IChatListItemProps> = ({ id, name, timestamp, isSelected }) => {
  // const showPreview = !features[FeatureKeys.SimplifiedExperience].enabled && preview;
  // const showActions = features[FeatureKeys.SimplifiedExperience].enabled && isSelected;
  const dispatch = useAppDispatch();

  const navigator = useNavigate();
  const handleSelect = (_ev: any) => {
    navigator(`/chat/${id}`);
    dispatch(setSelectedPlugin(null));
    // history.pushState(null, 'chat', `http://localhost:3000/chat/${id}`);
    // void chat.changeChatRoom(id);
  };

  const time = new Date(timestamp).toLocaleString();

  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [chatOption, setChatOption] = useState(false);

  const handledeleteClick = () => {
    setIsDeleting(true);
    setChatOption(false);
  };
  const handleEditClick = () => {
    setIsEditing(true);
    setChatOption(false);
  };

  return (
    <Tooltip theme="inverse" message={name} className="tooltip-wrap-no-inline" position={['bottom', 'right']}>
      <li className="chat-list-item" aria-label={name}>
        <a
          href="#"
          role="button"
          className={classNames('chat-link', { 'is-active': isSelected })}
          onClick={handleSelect}
        >
          <strong className="name">{name}</strong>
          <em className="desc">{time}</em>
        </a>
        <Popover
          isOpen={chatOption}
          popoverState={setChatOption}
          trigger={
            <Button className="chat-more" control icon="more" textBlind>
              더보기
            </Button>
          }
        >
          <div className="chat-option">
            <Button
              control
              icon="edit"
              onClick={() => {
                handleEditClick();
              }}
            >
              이름 바꾸기
            </Button>
            <Button control icon="delete" onClick={handledeleteClick}>
              삭제
            </Button>
          </div>
        </Popover>
        {isEditing && (
          <EditChatName
            name={name}
            chatId={id}
            exitEdits={() => {
              setIsEditing(false);
            }}
          />
        )}
        {/* AlertModal로 처리하려고 했으나, dispatch 에 id값이 가장 마지막 값으로 덮어씌워져서 제대로 삭제 안되는 문제 있음 */}
        {isDeleting && <DeleteModal chatId={id} onClick={setIsDeleting} />}{' '}
      </li>
    </Tooltip>
  );
};
