import React, { KeyboardEvent, MouseEvent, useRef, useState, ReactElement } from 'react';
import { Icon } from './Icon';
import classNames from 'classnames';

// Tab 컴포넌트의 Props 타입 정의
interface TabProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode;
  icon?: string;
  controlsName: string;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
  isActive?: boolean;
  onKeyDown: (event: KeyboardEvent<HTMLButtonElement>) => void;
  tabRef: React.RefObject<HTMLButtonElement>;
}

export const Tab: React.FC<TabProps> = ({
  label,
  icon,
  controlsName,
  onClick,
  isActive,
  onKeyDown,
  tabRef,
  ...props
}) => {
  return (
    <button
      type="button"
      role="tab"
      className="dsx-Tab"
      aria-controls={controlsName}
      aria-selected={isActive}
      onClick={onClick}
      onKeyDown={onKeyDown}
      ref={tabRef}
      {...props}
    >
      {icon && <Icon name={icon || 'empty'} />}
      <span className="dsx-Tab-name">{label}</span>
    </button>
  );
};

// TabPanel 컴포넌트의 Props 타입 정의
interface TabPanelProps {
  children: React.ReactNode;
  isActive?: boolean;
  controlsId: string;
  label: React.ReactNode;
  icon?: string;
}

export const TabPanel: React.FC<TabPanelProps> = ({ children, isActive, controlsId }) => {
  return (
    <div id={controlsId} role="tabpanel" className="dsx-TabPanel" data-state={isActive ? 'show' : 'hide'}>
      {isActive ? children : null}
    </div>
  );
};

interface TabListProps {
  children: React.ReactNode;
  activeTabKey?: string;
  onTabChange?: (key: string) => void;
  variant?: 'text' | 'box';
  isSmall?: boolean;
}

export const TabList: React.FC<TabListProps> = ({
  children,
  activeTabKey,
  onTabChange,
  variant = 'text',
  isSmall = false,
}) => {
  const tabPanels = React.Children.toArray(children) as Array<ReactElement<TabPanelProps>>;

  const [internalActiveTabKey, setInternalActiveTabKey] = useState<string | undefined>(
    activeTabKey ?? tabPanels[0]?.props?.controlsId,
  );
  const tabRefs = useRef<HTMLButtonElement[]>([]);

  const currentActiveTabKey = activeTabKey ?? internalActiveTabKey;

  const handleTabChange = (key: string) => {
    if (onTabChange) {
      onTabChange(key);
    } else {
      setInternalActiveTabKey(key);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>, index: number) => {
    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        event.preventDefault();
        if (index < tabPanels.length - 1) {
          tabRefs.current[index + 1].focus();
        }
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        event.preventDefault();
        if (index > 0) {
          tabRefs.current[index - 1].focus();
        }
        break;
      case 'Home':
        event.preventDefault();
        tabRefs.current[0].focus();
        break;
      case 'End':
        event.preventDefault();
        tabRefs.current[tabPanels.length - 1].focus();
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div
        className={classNames('dsx-TabList', `dsx-TabList--${variant}`, isSmall && `dsx-TabList--small`)}
        role="tablist"
      >
        {tabPanels.map((panel, index) => (
          <Tab
            key={panel.props.controlsId}
            controlsName={panel.props.controlsId}
            label={panel.props.label}
            icon={panel.props.icon}
            isActive={panel.props.controlsId === currentActiveTabKey}
            onClick={() => {
              handleTabChange(panel.props.controlsId);
            }}
            onKeyDown={(event) => {
              handleKeyDown(event, index);
            }}
            //@ts-expect-error - The ref type is `any` due to limitations in typing the `ref` for different HTML elements.
            tabRef={(el: React.RefObject<HTMLButtonElement>) => (tabRefs.current[index] = el)}
          />
        ))}
      </div>
      {tabPanels.map((panel) => {
        return React.cloneElement(panel, {
          isActive: panel.props.controlsId === currentActiveTabKey,
        });
      })}
    </>
  );
};
