import React, { useState, useRef, ReactNode, CSSProperties } from 'react';
import classNames from 'classnames';
import { Combobox } from './Combobox';
import { Popover } from './Popover';
import { Icon } from './Icon';

interface DropdownMenuOption {
  value: string | number;
  label: string;
  icon?: string;
  onClick?: () => void;
}

interface DropdownMenuProps {
  label: string;
  options: DropdownMenuOption[];
  onOptionSelect?: (value: string | number) => void;
  comboboxProps?: Record<string, any>;
  trigger?: ReactNode;
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
  position?: 'top' | 'bottom' | Array<'top' | 'bottom' | 'start' | 'end'>;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({
  label,
  options,
  onOptionSelect,
  trigger,
  className,
  style,
  disabled = false,
  comboboxProps,
  position = 'bottom',
  ...props
}) => {
  const [popoverState, setPopoverState] = useState(false);
  const changedPopoverState = (state: boolean) => {
    setPopoverState(state);
  };

  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (value: string | number, onClick?: () => void) => {
    if (onOptionSelect) {
      onOptionSelect(value);
    }
    if (onClick) {
      onClick();
    }
    changedPopoverState(false);
  };

  return (
    <div ref={menuRef} className={classNames('dsx-DropdownMenu', className)} style={style}>
      <Popover
        trigger={
          React.isValidElement(trigger) ? (
            React.cloneElement(trigger, {
              //@ts-expect-error - The ref type is `any` due to limitations in typing the `ref` for different HTML elements.
              ref: triggerRef,
              onClick: () => {
                changedPopoverState(!popoverState);
              },
              role: 'combobox',
              'aria-expanded': popoverState,
            })
          ) : (
            <Combobox
              ref={triggerRef}
              onClick={() => {
                changedPopoverState(!popoverState);
              }}
              role="combobox"
              aria-expanded={popoverState}
              disabled={disabled}
              label={label}
              {...comboboxProps}
              {...props}
            />
          )
        }
        isOpen={popoverState}
        popoverState={changedPopoverState}
        position={position} // Pass the position prop here
        style={{ minWidth: menuRef.current?.offsetWidth }}
      >
        {options.length > 0 && (
          <ul className={classNames('dsx-DropdownMenu-listbox')} data-state={popoverState ? 'show' : 'hide'}>
            {options.map((option) => (
              <li key={option.value}>
                <button
                  type="button"
                  className="dsx-DropdownMenu-option"
                  onClick={() => {
                    handleOptionClick(option.value, option.onClick);
                  }}
                >
                  {option.icon && <Icon name={option.icon} />}
                  {option.label}
                </button>
              </li>
            ))}
          </ul>
        )}
      </Popover>
    </div>
  );
};
