import { useState } from 'react';
import { Button, ButtonArea, Form, FormItem, Input, Select, Textarea, Avatar } from '../../../components/base';
import { IPluginPromptData } from '../../../libs/models/studio/PluginPromptList';
import { useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import PluginPromptCreateDialog from '../PluginPromptCreateDialog';

interface Errors {
  promptCategoryId: string;
  name: string;
  description: string;
  profileUrl: string;
}

interface IPromptCreateProfile {
  formData: IPluginPromptData;
  setFormData: React.Dispatch<React.SetStateAction<IPluginPromptData>>;
  onNext: () => void;
}

const PromptCreateProfile: React.FC<IPromptCreateProfile> = ({ formData, setFormData, onNext }) => {
  const { categoryList } = useAppSelector((state: RootState) => state.category);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const [errors, setErrors] = useState<Errors>({
    promptCategoryId: '',
    name: '',
    description: '',
    profileUrl: '',
  });

  const validate = () => {
    const newErrors = {
      promptCategoryId: '',
      name: '',
      description: '',
      profileUrl: '',
    };
    if (!formData.profileUrl) newErrors.profileUrl = '이미지를 선택해주세요.';
    if (!formData.promptCategoryId || formData.promptCategoryId === 'all')
      newErrors.promptCategoryId = '카테고리명을 선택해주세요.';
    if (!formData.name) newErrors.name = '프롬프트 템플릿 명을 입력해주세요.';
    if (!formData.description) newErrors.description = '소개를 입력해주세요.';
    setErrors(newErrors);
    return Object.values(newErrors).every((value) => value.trim().length === 0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: value ? '' : prevErrors[name as keyof Errors],
    }));
  };

  const handleSelectChange = (selectedOption: string | number) => {
    setFormData((prev) => ({ ...prev, promptCategoryId: selectedOption as string }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      promptCategoryId: selectedOption ? '' : prevErrors.promptCategoryId,
    }));
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validate()) {
      onNext();
    }
  };

  // const handleAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = e.target.files?.[0]; // optional chaining을 사용하여 null 체크
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       setFormData((prev) => ({ ...prev, profileUrl: reader.result as string }));
  //       setErrors((prevErrors) => ({ ...prevErrors, profileUrl: '' }));
  //     };
  //     reader.readAsDataURL(file);
  //   } else {
  //     setErrors((prevErrors) => ({ ...prevErrors, profileUrl: '이미지를 선택해주세요.' }));
  //   }
  // };

  return (
    <>
      <div className="form-avatar">
        <Avatar
          as="button"
          size="9"
          variant={formData.profileUrl ? 'default' : 'empty'}
          src={
            formData.profileUrl &&
            `${formData.profileUrl}?v=${formData.profileUrlVersion ? formData.profileUrlVersion : ''}`
          }
          label={formData.profileUrl ? '프롬프트 프로필 이미지' : '이미지는 필수로 등록해주세요'}
          accent="normal"
          badgeProps={{
            icon: 'plus',
            label: '프로필 이미지 등록',
          }}
          invalid={!!errors.profileUrl}
          onClick={handleDialogOpen}
        />
      </div>
      {/* 프로필 이미지 등록 팝업 */}
      <PluginPromptCreateDialog
        isOpen={dialogOpen}
        onClose={handleDialogClose}
        setFormData={setFormData}
        formData={formData}
      />
      {/* //프로필 이미지 등록 팝업 */}
      <Form variant="vertical" className="form-field" column={2} onSubmit={handleFormSubmit}>
        <FormItem label="카테고리" error={errors.promptCategoryId} isRequired>
          <Select
            variant="filled"
            size="xlarge"
            placeholder="카테고리명"
            name="promptCategoryId"
            value={formData.promptCategoryId as string}
            options={categoryList}
            onChange={handleSelectChange}
            full
          />
        </FormItem>
        <FormItem label="프롬프트 템플릿 명" error={errors.name} isRequired>
          <Input
            variant="filled"
            size="xlarge"
            placeholder="프롬프트 템플릿 명을 입력하세요"
            maxLength={16}
            name="name"
            showCount
            value={formData.name as string}
            onChange={handleChange}
            full
          />
        </FormItem>
        <FormItem label="소개" error={errors.description} isRequired className="dsx-w-full">
          <Textarea
            variant="filled"
            size="xlarge"
            placeholder={
              '어떤 목적의 프롬프트 템플릿인지 간단한 소개를 적어주세요.\nex) 장문의 회의내용 텍스트를 안건별로 요약, 액션아이템을 정리하여 회의록을 작성해줘요.'
            }
            rows={8}
            maxLength={300}
            showCount
            name="description"
            value={formData.description as string}
            onChange={handleChange}
          />
        </FormItem>
      </Form>
      <ButtonArea align="end">
        <Button type="button" variant="primary" size="large" suffixIcon="chevron" onClick={handleFormSubmit}>
          다음
        </Button>
      </ButtonArea>
    </>
  );
};

export default PromptCreateProfile;
