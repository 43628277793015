import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Icon } from './Icon';
import { Heading } from './Heading';
import { Text } from './Text';
import '../../assets/styles/global.scss';
import '../../assets/styles/layout.scss';
import '../../assets/styles/studio.scss';
import Image from '../Image';

// Empty 컴포넌트의 Props 타입 정의
interface EmptyProps extends React.HTMLProps<HTMLDivElement> {
  variant?: 'data' | 'default'; // 'data'와 'default' 타입을 사용할 수 있는 경우
  icon?: string;
  image?: string;
  title?: string;
  desc?: ReactNode;
  children?: React.ReactNode;
  className?: string;
}

export const Empty: React.FC<EmptyProps> = ({ variant, icon, image, title, desc, children, className, ...props }) => {
  return (
    <div className={classNames('dsx-Empty', variant && `dsx-Empty--${variant}`, className)} {...props}>
      {image && <Image url={image} alt="" className="dsx-Empty-image" />}
      {icon && <Icon name={icon} className="dsx-Empty-icon" />}
      {variant === 'data' && <Icon name={icon ? icon : 'errorFill'} className="dsx-Empty-data" />}
      {title && (
        <Heading size="body1" weight="semibold">
          {title}
        </Heading>
      )}
      {desc && <Text size="label1">{desc}</Text>}
      {children && <div className="dsx-Empty-controls">{children}</div>}
    </div>
  );
};

export default Empty;
