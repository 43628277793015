// inputEditTab.js
import { useState, useRef, useEffect, forwardRef, ChangeEvent, FocusEvent, useImperativeHandle } from 'react';
import { IconButton } from './IconButton';

interface InputEditTableProps {
  id?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  maxLength?: number;
  name?: string;
}
// // ref가 처리할 수 있는 타입 정의

export interface InputEditTableHandle {
  focus: () => void;
}

// export interface FileUploaderHandle {
//   handleDelete: (file: FileType) => void;
// }

export const InputEditTable = forwardRef<InputEditTableHandle, InputEditTableProps>(function InputEditTable(
  { id, value, onChange, onFocus, onBlur, name, maxLength = 30 },
  ref,
) {
  const [inputValue, setInputValue] = useState<string>(value ?? ''); // 입력 값 상태 관리
  const [inputWidth, setInputWidth] = useState<number>(0); // 동적으로 계산할 입력 필드의 너비
  const fieldRef = useRef<HTMLInputElement>(null); // 입력 필드 참조
  const spanRef = useRef<HTMLSpanElement>(null); // 숨겨진 span 참조 (너비 계산용)
  const placeholderText = `공백 포함 최대 ${maxLength}자까지 입력할 수 있습니다.`; // 기본 placeholder 텍스트

  // 부모 컴포넌트가 focus() 메서드를 호출할 수 있도록 외부에서 접근할 수 있게 설정
  useImperativeHandle(ref, () => ({
    focus: () => {
      fieldRef.current?.focus(); // 입력 필드에 포커스 설정
    },
    // ...fieldRef.current, // HTMLInputElement의 모든 속성을 추가
  }));

  // 입력 값에 따른 동적인 너비 계산 함수
  const calculateWidth = (text: string) => {
    const spanElement = spanRef.current;
    if (spanElement) {
      spanElement.textContent = text; // 숨겨진 span에 텍스트 설정
      return spanElement.offsetWidth; // span의 실제 너비 반환
    }
    return 0;
  };

  useEffect(() => {
    const width = calculateWidth(inputValue || placeholderText); // 입력값에 대한 너비 계산
    setInputWidth(width);
  }, [inputValue]);

  // 입력값 변경 핸들러
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    if (onChange) onChange(e);
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    if (onFocus) onFocus(e);
  };

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    if (onBlur) onBlur(e);
  };

  return (
    <div className="dsx-InputEditTable">
      <input
        ref={fieldRef}
        id={id}
        className="dsx-InputEditTable-field"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        name={name}
        placeholder={placeholderText}
        maxLength={maxLength}
        style={{ width: `${inputWidth + 32}px` }} // 32px padding
      />
      {/* 숨겨진 span 요소로 실제 텍스트 너비 계산 */}
      <span
        ref={spanRef}
        className="dsx-InputEditTable-field"
        style={{
          visibility: 'hidden',
          position: 'absolute',
          whiteSpace: 'pre',
          display: 'inline-block',
        }}
      >
        {inputValue || placeholderText}
      </span>
      <IconButton
        name="edit"
        size="large"
        className="dsx-InputEditTable-edit"
        onClick={() => fieldRef.current?.focus()}
      >
        입력
      </IconButton>
    </div>
  );
});
