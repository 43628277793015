import React, { forwardRef, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';
import { Icon } from './Icon';

interface ComboboxProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  expanded?: boolean;
  label?: string;
  variant?: 'outlined' | 'filled' | 'ghost';
  icon?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const Combobox = forwardRef<HTMLButtonElement, ComboboxProps>(
  ({ expanded = false, label = 'Dropdown Menu', variant = 'outlined', className, icon, onClick, ...props }, ref) => (
    <button
      ref={ref}
      type="button"
      role="combobox"
      className={classNames('dsx-Combobox', `dsx-Combobox--${variant}`, className)}
      aria-expanded={expanded}
      onClick={onClick}
      {...props}
    >
      {icon && <Icon name={icon} />}
      <span className="dsx-Combobox-label">{label}</span>
      <Icon name="chevronBottom" className="dsx-Combobox-arrow" />
    </button>
  ),
);

Combobox.displayName = 'Combobox';
