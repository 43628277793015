import { Button } from '../../../components/Button';
import { Dropdown } from '../../../components/Dropdown';
import { Tooltip } from '../../../components/Tooltip';
import { AIModels, AIModel } from '../../../libs/models/app/AIModel';
import { Link } from '../../../components/Link';
import { Tag } from '../../../components/Tag';
import { ReactNode, useEffect, useState } from 'react';
import { checkMobileKate } from '../../../components/utils/common';
import { DropdownHeader } from '../../../components/DropdownHeader';

interface ChatModelSelectProps {
  className?: string;
  data: AIModels;
  selectedName: string;
  onSelect: (id: string) => void;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  message: ReactNode;
  disabled?: boolean;
}
// ChatModelSelect
export const ChatModelSelect: React.FC<ChatModelSelectProps> = ({
  className,
  data,
  selectedName,
  onSelect,
  isOpen,
  setIsOpen,
  disabled,
  message,
}) => {
  const changedModelState = (state: boolean) => {
    setIsOpen(state);
  };

  const [isDesktop, setIsDesktop] = useState(!checkMobileKate() || window.innerWidth <= 768);
  useEffect(() => {
    setIsDesktop(window.innerWidth > 768);
  }, []);

  return (
    <Dropdown
      trigger={
        <Tooltip theme="inverse" message={message}>
          <Button role="combobox" className="ds-control-dropdown" control disabled={disabled}>
            {selectedName}
          </Button>
        </Tooltip>
      }
      isOpen={isOpen}
      changeState={changedModelState}
      className={className}
    >
      {!isDesktop && (
        <DropdownHeader title="모델 선택">
          <Button
            className="ds-control-close"
            control
            textBlind
            onClick={() => {
              changedModelState(false);
            }}
          >
            닫기
          </Button>
        </DropdownHeader>
      )}

      {/* <div role="listbox" className="ds-popover model-list" data-state={isOpen ? 'show' : 'hide'}> */}
      {Object.values(data).map((item: AIModel, index) => (
        <div key={index} className="model-list-item">
          <Link
            className="model-item"
            aria-selected={item.model === selectedName}
            data-disabled={!item.isAvailable}
            tabIndex={item.isAvailable ? undefined : -1}
            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
              e.preventDefault();
              if (item.isAvailable) {
                onSelect(item.model);
                setIsOpen(false);
              }
            }}
          >
            <div className="symbol">
              <img
                src={item.profileUrl}
                onError={(e) => {
                  e.currentTarget.style.display = 'none';
                }}
              />
            </div>
            <div className="title">
              <strong className="name">{item.displayName}</strong>
              {item.tags?.map((tag, index) => (
                <Tag key={index} type="notice" className="suffix">
                  {tag}
                </Tag>
              ))}{' '}
              {!item.isAvailable && (
                <Tag key={index} type="notice" className="suffix">
                  내일 다시 사용가능해요
                </Tag>
              )}
              {item.usage && item.isAvailable && (
                <span className="suffix">
                  <b
                    className={
                      item.usage.remainCount ? (item.usage.remainCount < 15 ? 'txt-error' : 'txt-point') : 'txt-error'
                    }
                  >
                    {item.usage.remainCount}
                  </b>
                  /{item.usage.totalCount ? item.usage.totalCount : '제한 없음'}
                </span>
              )}
              <span className="desc">{item.description}</span>
            </div>
          </Link>
        </div>
      ))}
    </Dropdown>
  );
};
